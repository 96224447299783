<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12" v-if="loading">
        <b-spinner class="m-2" variant="primary" role="status"></b-spinner>
      </div>
      <div class="col-lg-12" v-else>
        <div class="card">
          <div class="card-body">
            <b-card-title>
              <h4 class="card-title">Architecture Style </h4>
            </b-card-title>
            <div class="row">
              <div class="col-3">
                <FormulateForm name="createItem" v-model="values" :schema="searchScheme" @submit="updateItem">

                  <FormulateInput type="submit">
                    <span v-if="updatingInfo">
                      <b-spinner class="vueformulate-loader"></b-spinner>
                      <span> Updating... </span>
                    </span>
                  </FormulateInput>
                </FormulateForm>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { property_types } from "@/config/api/property_types";
import { getFormData } from "@/utils/createForm";
import Swal from "sweetalert2";
import router from "@/router/index";

/**
 * Add Project Component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      loading: false,
      title: "Property",
      file: undefined,
      propertyId: "",
      items: [
        {
          text: "Architecture Style ",
          to: { name: "Architecture Style " },
        },
        {
          text: "Edit",
          active: true,
        },
      ],

      updatingInfo: false,
      values: {},
      searchScheme: [
        {
          index: 1,
          type: "text",
          name: "name",
          label: "Name",
          "validation-messages": { required: "Property Name is required" },
          validation: "required",
        },
        {
          index: 2,
          type: "text",
          name: "name_ar",
          label: "Name(Ar)",
          "validation-messages": { required: "Property Name(Ar) is required" },
          validation: "required",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    if (this.$route.query.id) {
      this.loading = true;
      this.propertyId = this.$route.query.id;
      this.searchProperty();
    }
  },
  methods: {
    updateItem(data) {
      this.updatingInfo = true;
      let api = undefined;

      let updateData = {
        name: data.name,
        name_ar: data.name_ar,
        abbreviation: data.abbreviation,
      };
      if (this.propertyId) {
        api = { ...property_types.update };
        updateData = {
          ...updateData,
          id: this.propertyId,
        };
        api.id = this.propertyId;
      } else {

        api = { ...property_types.create };
      }
      if (this.file !== undefined) {
        console.log(this.file);
        if (this.propertyId) {
          //Update methods
          updateData = {
            ...updateData,
            image: this.file.files[0].path.location,
          };
          api.data = updateData;
        } else {
          //Adding methods
          updateData = {
            ...updateData,
            file: this.file.files[0].file,
          };
          api.data = getFormData(updateData);
        }
      } else {
        api.data = { ...updateData };
      }
      this.generateAPI(api)
        .then(() => {
          this.updatingInfo = false;
          this.success();
        })
        .catch(() => {
          this.updatingInfo = false;
          this.failed();
        })
        .finally(() => {
          setTimeout(() => {
            this.searchProperty();
            router.push({ path: "/property-types" });
          }, 1000);
        });
    },
    success() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Architecture Style has been added",
        showConfirmButton: false,
        timer: 1000,
      });
    },
    failed() {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Please try again",
        showConfirmButton: false,
        timer: 1000,
      });
    },
    searchProperty() {
      if (!this.propertyId) return;
      this.loading = true;
      let api = { ...property_types.get };
      api.url += this.propertyId;
      this.generateAPI(api)
        .then((res) => {
          this.loading = false;
          this.file = undefined;
          this.values = res.data.model;
          console.log(res.data.model.name);
        })
        .catch((err) => {
          alert("Failed");
          console.log(err);
        });
    },
  },
};
</script>

